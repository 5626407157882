import React from 'react'
import moment from 'moment'
import difference from '@turf/difference'
import { polygon } from '@turf/helpers'
import styled from 'styled-components'
import { BaseTable, camelize, createTurfBbox, percent, Rating, secPercent, simplifiedGeometry, cityWithoutDVF, ErrorMarker, Asterich } from './Shared'

const franceRectangle = polygon([[
  [-5.5, 51.5], // Nord-Ouest
  [8.5, 51.5], // Nord-Est
  [8.5, 41.5], // Sud-Est
  [-5.5, 41.5], // Sud-Ouest
  [-5.5, 51.5]
]])

const CitySummary = ({ city, inseeData, mapboxToken, plot, report }) => {
  moment.locale('fr')
  const mapType = 'cityandyou/clujnjfso01bt01preqci71dv'
  const baseUrl = `https://api.mapbox.com/styles/v1/${mapType}/static`

  const canvasWidth = 900
  const canvasHeight = 600
  const aspectRatio = 271 / 268
  const bbox = createTurfBbox(city.geometry_to_geojson, aspectRatio)
  const location = `[${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}]`
  const maskedArea = difference(franceRectangle, city.geometry_to_geojson)
  const geometryToGeojson = {
    type: 'Feature',
    geometry: maskedArea.geometry,
    properties: { 'fill': '#4E5288', 'fill-opacity': 0.35 }
  }
  const simplifiedReversedGeometry = simplifiedGeometry(geometryToGeojson, 0.0005)
  const geojsonString = JSON.stringify(simplifiedReversedGeometry)
  const encodedGeoJSON = `geojson(${encodeURIComponent(geojsonString)})`
  const marker = `pin-s+d95d57(${plot.coordinates.lng},${plot.coordinates.lat})`
  const mapboxStaticImageUrl = `${baseUrl}/${encodedGeoJSON},${marker}/${location}/${canvasWidth}x${canvasHeight}@2x?access_token=${mapboxToken}`

  const toLocaleStringOrNull = (value) => {
    if (value) {
      return value.toLocaleString('fr-FR')
    }
    return '-'
  }

  return (
    <div className="d-flex flex-column pdf-content-inside">
      <div className="page-2-col align-start">
        <div>
          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Données générales</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>Nombre d&apos;habitants :</td>
                <td>{toLocaleStringOrNull(inseeData.age_distribution.ens)}</td>
              </tr>
              <tr>
                <td>Nbre total de logements :</td>
                <td>{toLocaleStringOrNull(inseeData.housing_type_distribution.ens)}</td>
              </tr>
              <tr>
                <td>Nbre de ménages :</td>
                <td>{toLocaleStringOrNull(inseeData.marital_distribution.ens)}</td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>% de logements sociaux :</td>
                {percent(inseeData, 'occupation_distribution', 'hlm_tenant')}
              </tr>
            </tbody>
          </BaseTable>

          { report === 'landReport' && (
            <BaseTable className="col-2 white-background mb-12">
              <thead>
                <tr><th colSpan="2">Principaux bailleurs sociaux</th></tr>
              </thead>
              <tbody>
                {
                  city.top_5_hlm_tenants.map((owner) => (
                    <tr key={owner.id}>
                      <td>{camelize(owner.name)} :</td>
                      <td>{(Math.round((owner.housing_area / city.housing_area_hlm_tenants) * 100))}<span> % du parc</span></td>
                    </tr>
                  ))
                }
              </tbody>
            </BaseTable>
          )}

          { report === 'marketReport' && (
            <BaseTable className="col-2 white-background mb-12">
              <thead>
                <tr><th colSpan="2">Statut d&apos;occupation des résidences principales</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Propriétaires :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.occupation_distribution.owner)} {secPercent(inseeData, 'occupation_distribution', 'owner')}
                  </td>
                </tr>
                <tr>
                  <td>Locataires :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.occupation_distribution.tenant)} {secPercent(inseeData, 'occupation_distribution', 'tenant')}
                  </td>
                </tr>

                <tr>
                  <td className="bullet">Privés :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.occupation_distribution.private_tenant)} {secPercent(inseeData, 'occupation_distribution', 'private_tenant')}
                  </td>
                </tr>
                <tr>
                  <td className="bullet">HLM :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.occupation_distribution.hlm_tenant)} {secPercent(inseeData, 'occupation_distribution', 'hlm_tenant')}
                  </td>
                </tr>
                <tr>
                  <td>Logés gratuitement :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.occupation_distribution.free_housing)} {secPercent(inseeData, 'occupation_distribution', 'free_housing')}
                  </td>
                </tr>
              </tbody>
            </BaseTable>
          )}


          { report === 'marketReport' && (
            <BaseTable className="col-2 white-background mb-12">
              <thead>
                <tr><th colSpan="2">Répartition matrimoniale des ménages</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Célibataires :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.single)} {secPercent(inseeData, 'marital_distribution', 'single')}
                  </td>
                </tr>
                <tr>
                  <td>Familles monoparentales :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.single_parent)} {secPercent(inseeData, 'marital_distribution', 'single_parent')}
                  </td>
                </tr>
                <tr>
                  <td>Ménages en couple :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.couple)} {secPercent(inseeData, 'marital_distribution', 'couple')}
                  </td>
                </tr>
                <tr>
                  <td className="bullet">Sans enfants :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.marital_distribution.without_kids)}
                  </td>
                </tr>
                <tr>
                  <td className="bullet">Avec enfants :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.marital_distribution.with_kids)}
                  </td>
                </tr>
                <tr>
                  <td>Ménages autres sans famille:</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.other)} {secPercent(inseeData, 'marital_distribution', 'other')}
                  </td>
                </tr>
              </tbody>
            </BaseTable>
          )}

          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Dynamique des permis de construire</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>Nbre de PC déposés ou autorisés en 2023 :</td>
                <td>{city.building_permits_n_1}</td>
              </tr>
              <tr>
                <td>Nbre de PC déposés ou autorisés en 2022 :</td>
                <td>{city.building_permits_n_2}</td>
              </tr>
              <tr>
                <td className="comment">Permis de + de 500 m² en résidentiel.</td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>Typo. moyennes <br/>commune **:</td>
                <td className='d-flex gap-4 justify-content-between'>
                  {
                    city.housing_typology ?
                      Object.keys(city.housing_typology).map((typ) => (
                        <div key={typ}>
                          <div className="no-bold color-light-grey">{typ === 'T6' ? 'T6+' : typ}</div>
                          <div>{parseInt(city.housing_typology[typ] * 100)}%</div>
                        </div>
                      )) : '-'
                  }
                </td>
              </tr>
            </tbody>
          </BaseTable>
        </div>

        <div>
          <CitySummaryMap url={mapboxStaticImageUrl} className="mb-12" />

          {report === 'landReport' && (
            <BaseTable className="col-2">
              <thead>
                <tr>
                  <th colSpan="2">Prix moyen disponible sur la commune</th>
                  {cityWithoutDVF(city.code) && <th colSpan="2"><div className="d-flex justify-end w-full mb-5">{ErrorMarker}</div></th>}
                </tr>
              </thead>
              {cityWithoutDVF(city.code) ? (
                <tbody>
                  <tr>
                    <td className="pt-5" colSpan="4">
                      <div className="w-full d-flex justify-content-center">
                        En l'absence des données de marché complètes sur le secteur, nous ne sommes pas en mesure de vous délivrer d'estimation de prix.
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>Appartement neuf<br/>(T3 en TVA 20%) :</td>
                    <td>
                      {Math.round(plot.city_price_new.adjusted_price_area.test).toLocaleString('fr-FR')}<span> €/m² shab pki</span> <br/> <Rating index={plot.city_price_new.confidence_index.test} pertinence={false} />
                    </td>
                  </tr>
                  <tr>
                    <td>Maison dans l’ancien :</td>
                    <td>{Math.round(plot.price_old_house.adjusted_price_area.test).toLocaleString('fr-FR')}<span> €/m²</span></td>
                  </tr>
                  <tr className="relevance comment">
                    <td>Pertinence :</td>
                    <td><Rating index={plot.price_old_house.confidence_index.test} pertinence={false} /></td>
                  </tr>
                  <tr>
                    <td>Appartement dans l’ancien :</td>
                    <td>{Math.round(plot.price_old_apartment.adjusted_price_area.test).toLocaleString('fr-FR')}<span> €/m²</span></td>
                  </tr>
                  <tr className="relevance comment">
                    <td>Pertinence :</td>
                    <td><Rating index={plot.price_old_apartment.confidence_index.test} pertinence={false} /></td>
                  </tr>
                </tbody>
              )}
            </BaseTable>
          )}
        </div>

      </div>
      <Asterich flexGrow>
        <div>
          <span className="color-candy-primary">*</span>
          Données issues du recensement INSEE 2020
          <br />
          <span className="color-candy-primary">**</span>
          Ventilation moyenne des typologies contatées dans les permis de construire déposés sur la
          <br />
          commune sur les 5 dernières années.
        </div>
      </Asterich>
    </div>
  )
}

const CitySummaryMap = styled.div`
  width: 100%;
  height: 450px;
  background-image: url("${props => props.url}");
  background-size: cover;
  background-position: center;
`

export default CitySummary

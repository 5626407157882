import React, { useState } from 'react'
import moment from 'moment'
import MapboxMap, { Marker } from 'react-mapbox-wrapper'
import {
  adjustSuperposition,
  camelize,
  CircleProgram,
  formatAddress,
  SerialDataTable,
  defineBounds,
  WarningMissingData
} from './Shared'

const BuildingPermits = ({ buildingPermits, mapboxToken, plot }) => {
  moment.locale('fr')
  const [map, setMap] = useState(null)
  const mapType = 'mapbox://styles/cityandyou/clrj9yf1800nx01pe6d0p14c7'
  const bounds = defineBounds(buildingPermits.building_permits)
  const markers = adjustSuperposition(buildingPermits.building_permits, bounds, true)
  const newBounds = defineBounds(markers)

  const onMapLoad = (mapInstance) => {
    setMap(mapInstance)
    mapInstance.addSource('city', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: plot.geometry_to_geojson,
      }
    })

    mapInstance.addLayer({
      id: 'city-outline',
      type: 'line',
      source: 'city',
      layout: {},
      paint: {
        'line-color': '#d95d57',
        'line-width': 2,
      }
    })
  }

  const BpMarker = ({ index, housing_number, floor_area }) => (
    <div className="d-flex a-center">
      <CircleProgram className="bp">{index + 1}</CircleProgram>
      <div className="marker-content marker-content-bp">
        <div><span className="bold">{floor_area?.toLocaleString('fr-FR') || '-'}</span> m²</div>
        <div><span className="bold">{housing_number || '-'}</span> lot{housing_number > 1 ? 's' : ''}</div>
      </div>
    </div>
  )

  return (
    <div className="align-start pdf-content-inside">
      <div id="near-programs-map" className="mb-12">
        <MapboxMap
          accessToken={mapboxToken}
          coordinates={plot.coordinates}
          bounds={newBounds}
          fitBoundsOptions={{ padding: { top: 60, right: 110, bottom: 60, left: 60 } }}
          onLoad={(e) => onMapLoad(e)}
          mapboxStyle={mapType}
          preserveDrawingBuffer
        >
          { map && markers.map((marker, index) => (
            <Marker
              key={marker.lat}
              map={map}
              coordinates={{ lat: marker.lat, lng: marker.lon }}
              anchor="left"
              offset={[-10, 0]}
            >
              <BpMarker
                index={index}
                nbLots={marker.housing_number}
                floor_area={marker.floor_area}
                housing_number={marker.housing_number}
              />
            </Marker>
          ))}
        </MapboxMap>
      </div>
      {
       buildingPermits.building_permits.length > 0 ? (
          <SerialDataTable>
            <thead>
              <tr>
                <th className="w-5" />
                <th className="w-25">Adresse</th>
                <th className="w-25">Société</th>
                <th className="w-10">Dépôt</th>
                <th className="w-15">Avancement</th>
                <th className="w-10">Surface</th>
                <th className="w-10">Nb de logts.</th>
              </tr>
            </thead>
            <tbody>
              {buildingPermits.building_permits.map((bp, index) => (
                <tr key={bp.permit_id} className="height-40px yellow-background">
                  <td className="pl-5 td-bp-marker"><CircleProgram className="bp">{index + 1}</CircleProgram></td>
                  <td className="td-container">{formatAddress(bp.address)}</td>
                  <td className="td-container"><div className="multi-line-ellipsis">{bp.company}</div></td>
                  <td>{bp.approval_date ? moment(bp.approval_date).format('YYYY') : '-'}</td>
                  <td>{camelize(bp.status)}</td>
                  <td>{bp.floor_area ? <span>{bp.floor_area.toLocaleString('fr-FR')} m²</span> : '-'}</td>
                  <td>{bp.housing_number}</td>
                </tr>
              ))}
            </tbody>
          </SerialDataTable>
        ) : (
          <WarningMissingData yellow>
            <p>
              <b>Nous n'avons pas trouvé de permis de construire</b><br/>
              dans un rayon de 5 km.
            </p>
          </WarningMissingData>
        )
      }
    </div>
  )
}

export default BuildingPermits

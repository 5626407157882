import React from 'react'
import styled from 'styled-components'
import { BaseTable, camelize, lineBreak, singleHead2, Stickers, Rating, cityWithoutDVF, ErrorMarker } from './Shared'

const GeneralSummary = ({ city, mapboxToken, multiPlots, plot, plotGeometry, report }) => {
  const mapType = report === 'marketReport' ? 'cityandyou/clujnjfso01bt01preqci71dv' : 'cityandyou/clsn62gxt003l01qqhyujbw4j'
  const baseUrl = `https://api.mapbox.com/styles/v1/${mapType}/static`
  const zoom = 16
  const width = 680
  const height = 520
  const location = `${plot.coordinates.lng},${plot.coordinates.lat},${zoom}`
  const geometryToGeojson = {
    type: 'Feature',
    geometry: plotGeometry,
    properties: {
      'stroke': '#d95d57',
      'stroke-width': 2,
      'fill-opacity': 0
    }
  }
  const geojsonString = JSON.stringify(geometryToGeojson)
  const encodedGeoJSON = `geojson(${encodeURIComponent(geojsonString)})`
  const mapboxStaticImageUrl = `${baseUrl}/${encodedGeoJSON}/${location}/${width}x${height}@2x?access_token=${mapboxToken}`

  return (
    <div className="pdf-content-inside">
      <GeneralSummaryMap url={mapboxStaticImageUrl} className="mb-12" />
      <BaseTable className="col-2 location mb-12">
        <tbody>
          <tr><td>{plot.address.replace(/, \d+.*/, '')}</td></tr>
          <tr><td className="bold">{plot.postal_code} {city.name}</td></tr>
        </tbody>
      </BaseTable>

      { report === 'marketReport' && (
        <BaseTable className="green-background width-240 mb-12">
          <thead>
            <tr>
              <th colSpan="2">Prix de vente recommandé</th>
              {cityWithoutDVF(city.code) && <th colSpan="2"><div className="d-flex justify-end w-full mb-5">{ErrorMarker}</div></th>}
            </tr>
          </thead>
          { cityWithoutDVF(city.code) ? (
            <tbody>
              <tr>
                <td className="pt-5" colSpan="4">
                  <div className="">
                    En l'absence des données de marché complètes sur le secteur,<br/>
                    nous ne sommes pas en mesure de vous délivrer d'estimation de prix.
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="pt-5">Appartement neuf (TVA 20%) :</td>
                <td className="d-flex gap-4 pt-5">
                  <div className="w-100">
                    { Math.round(plot.city_price_new_by_typo.moy.square_meter_price).toLocaleString('fr-FR') }
                    <span> €/m² shab pki</span>
                  </div>
                  <Rating index={plot.city_price_new.confidence_index?.test} />
                </td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>Appartement dans l’ancien :</td>
                <td className="d-flex gap-4">
                  <div className="w-100">
                    { Math.round(plot.price_old_apartment.adjusted_price_area?.test).toLocaleString('fr-FR') }
                    <span> €/m²</span>
                  </div>
                  <Rating index={plot.price_old_apartment.confidence_index?.test} />
                </td>
              </tr>
              <tr>
                <td>Maison dans l’ancien :</td>
                <td className="d-flex gap-4">
                  <div className="w-100">
                    { Math.round(plot.price_old_house.adjusted_price_area?.test).toLocaleString('fr-FR') }
                    <span> €/m²</span>
                  </div>
                  <Rating index={plot.price_old_house.confidence_index?.test} />
                </td>
              </tr>
            </tbody>
          )}
        </BaseTable>
      )}

      <div className="page-2-col">
        <div>
          { report === 'landReport' && (
            <BaseTable className="col-2 mb-12">
              <tbody>
                <tr>
                  <td>Parcelle{multiPlots ? 's' : ''} :</td>
                  <td>{lineBreak(plot.land_registry_id_short.join(', '))}</td>
                </tr>
                <tr>
                  <td>{multiPlots ? 'Surface des terrains' : 'Surface du terrain'} :</td>
                  <td>{plot.area.toLocaleString('fr-FR')}<span> m<sup>2</sup></span></td>
                </tr>
              </tbody>
            </BaseTable>
          )}

          <BaseTable className="col-2 mb-12">
            <tbody>
              <tr>
                <td>Gare la plus proche :</td>
                <td>{plot.station ? camelize(plot.station.name) : '-'}</td>
              </tr>
              <tr>
                <td>Distance (vol d’oiseau):</td>
                {plot.station?.distance ? <td>{plot.station?.distance}<span> m</span></td> : <td>-</td>}
              </tr>
            </tbody>
          </BaseTable>
        </div>
      </div>
      <Stickers
        reducedVat={plot.reduced_vat}
        pinel={city.pinel}
      />
    </div>
  )
}

const GeneralSummaryMap = styled.div`
  width: 100%;
  height: 450px;
  background-image: url("${props => props.url}");
  background-size: cover;
  background-position: center;
`

export default GeneralSummary
